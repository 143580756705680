<template>
  <div>
    <b-modal
      id="product-modal"
      hide-header
      hide-footer
      no-stacking
      centered size="lg"
      @hidden="closeModal"
      @show="onShow"
    >
      <div class="modal-header">
        <div class="modal-title">Cadastro de produto</div>
        <span class="icon-box"><v-close class="icon stroke" @click="closeModal"  /></span>
      </div>

      <validation-observer ref="productForm">
        <div class="row">
          <div class="col-12 padding_24_24_0">
            <b-form-group
              :invalid-feedback="'Campo obrigatório'"
              :state="isValidState(form.name)"
            >
              <label for="name" class="title">Nome do produto</label>
              <b-form-input
                trim
                id="name"
                autocomplete="off"
                placeholder="Insira o nome do produto"
                v-model="form.name"
              />
            </b-form-group>
          </div>

          <div class="col-4 padding_24_0_0_24">
            <b-form-group
              id="field_barcode"
            >
              <label for="barcode" class="title">
                Código de barras
                <span class="help optional">(opcional)</span>
              </label>
              <b-form-input
                id="barcode"
                trim
                autocomplete="off"
                placeholder="Código de barras"
                v-model="form.barcode"
              />
            </b-form-group>
          </div>

          <div class="col-8 padding_24_24_0_8">
            <b-form-group>
              <label for="measureUnit" class="title">Unidade de medida</label>
              <multiselect
                id="measureUnit"
                v-model="form.measure_unit"
                track-by="id"
                label="label"
                placeholder="Selecionar"
                :options="measureUnits"
                :allow-empty="false"
                :option-height="40"
                :showLabels="false"
                :showNoResults="false"
                class="with-border"
                :disabled="false"
                @select="el => ( this.form.measure_unit_id = el.id )"
              >
                <template slot="caret">
                  <div class="chevron">
                    <v-chevron-down />
                  </div>
                </template>

                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.short_name }} - {{ option.name}}
                </template>

                <template slot="noOptions">
                  Nenhuma opção
                </template>

                <template slot="noResult">
                  Nenhum resultado
                </template>
              </multiselect>
              <div v-if="validated && !form.measure_unit_id" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-form-group>
          </div>

          <div class="col-4 padding_16_0_0_24">
            <b-form-group
              id="field_expiration_notice"
              :invalid-feedback="'Campo obrigatório'"
              :state="isValidStateNumber(form.expiration_notice)"
            >
              <label for="expiration_notice" class="title">Aviso de vencimento</label>
              <b-form-input
                autocomplete="off"
                id="expiration_notice"
                trim
                placeholder="Inserir dias"
                type="number"
                min="0"
                v-model="form.expiration_notice"
                @input="value => { this.form = {...this.form, expiration_notice: !!value && Math.abs(value) >= 0 ? Math.abs(value) : null } }"
              />
            </b-form-group>
          </div>

          <div class="col-4 padding_16_0_0_8">
            <b-form-group
              id="field_minimum_quantity"
              :invalid-feedback="'Campo obrigatório'"
              :state="isValidStateNumber(form.minimum_quantity)"
            >
              <label for="minimum_quantity" class="title">Quantidade mínima</label>
              <b-form-input
                autocomplete="off"
                id="minimum_quantity"
                trim
                type="number"
                min="0"
                v-model="form.minimum_quantity"
                @input="value => { this.form = { ...this.form, minimum_quantity: !!value && Math.abs(value) >= 0 ? Math.abs(value) : null } }"
              />
            </b-form-group>
          </div>

          <div class="col-4 padding_16_24_0_8">
            <b-form-group>
              <label for="batch" class="title">Controle de lotes</label>
              <multiselect
                v-model="form.batch_control"
                track-by="value"
                label="label"
                placeholder="Selecionar"
                :options="batchControlOptions"
                :allow-empty="false"
                :option-height="40"
                :showLabels="false"
                :showNoResults="false"
                class="with-border"
                :disabled="configuration.batch_control_required"
              >
                <template slot="caret">
                  <div class="chevron">
                    <v-chevron-down />
                  </div>
                </template>
                <template slot="singleLabel" slot-scope="{ option }">
                  {{ option.label }}
                </template>

                <template slot="noOptions">
                  Nenhuma opção
                </template>

                <template slot="noResult">
                  Nenhum resultado
                </template>
              </multiselect>
              <div v-if="validated && !form.batch_control" class="custom-invalid-feedback">Campo obrigatório</div>
            </b-form-group>
          </div>

          <div class="col-12 no-padding">
            <hr/>
          </div>

          <div class="col-12 padding_24_24_0">
            <b-form-group
              id="field_details"
            >
              <label for="details" class="title">
                Detalhes do produto
                <span class="help optional">(opcional)</span>
              </label>
              <b-form-input
                autocomplete="off"
                id="details"
                trim
                placeholder="Adicionar detalhes"
                v-model="form.details"
              />
            </b-form-group>
          </div>
        </div>
      </validation-observer>

      <div v-if="!form.id" class="md-button">
        <b-button
          class="wh-button button"
          variant="primary"
          size="lg"
          @click="createProduct"
        >
            Salvar produto
        </b-button>
      </div>

      <div v-else class="md-button">
        <b-button
          class="wh-button button"
          variant="primary"
          size="lg"
          @click="updateProduct"
        >
            Atualizar produto
        </b-button>
      </div>

    </b-modal>
  </div>
</template>
<script>
import Close from '@/assets/icons/close.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import { EventBus } from '@/utils/eventBus';
export default {
  name: 'ProductModal',
  components: {
    'v-close': Close,
    'v-chevron-down': ChevronDown,
  },
  props: {
    product: Object,
  },
  mounted() {
    this.api.getMeasureUnits()
      .then(res => {
        this.measureUnits = res.data.map(el => ({ ...el, label: `${el.short_name} - ${el.name}` }));
      });
  },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      configuration: {},
      measureUnits: [],
      validated: false,
      form: {},
      batchControlOptions: [{ label: 'Sim', value: true }, { label: 'Não', value: false }],
    }
  },
  methods: {
    onShow() {
      this.getWarehouseConfigurations();
    },

    getWarehouseConfigurations() {
      this.api.getWarehouseConfigurations(this.clinic.id)
      .then(res => {
        this.configuration = res.data;
        if (res.data.batch_control_required) {
          this.form.batch_control = { label: 'Sim', value: true };
        }
      })
      .catch(err => {
        this.$toast.error(err.message);
      })
    },

    createProduct() {
      if (!this.isValidForm()) return;
      const props = {
        clinic_id: this.clinic.id,
        name: this.form.name,
        barcode: this.form.barcode,
        measure_unit_id: this.form.measure_unit.id,
        expiration_notice: this.form.expiration_notice,
        minimum_quantity: this.form.minimum_quantity,
        batch_control: this.form.batch_control.value,
        details: this.form.details,
      };
      const isLoading = this.$loading.show();
      this.api.createProduct(props)
      .then(() => {
        this.$toast.success('Produto cadastrado com sucesso!');
        EventBus.$emit('reloadProducts');
        this.clearForm();
      })
      .catch(err => {
        this.$toast.error(err.message);
      })
      .finally(() => isLoading.hide());
    },

    updateProduct() {
      if (!this.isValidForm()) return;
      const isLoading = this.$loading.show()
      this.api.updateProduct(this.form.id, this.form)
      .then(() => {
        this.clearForm();
        this.$toast.success('Produto atualizado com sucesso!');
        this.$bvModal.hide('product-modal');
        this.$emit('onHide', true);
        EventBus.$emit('reloadProducts');
      })
      .catch(err => {
        this.$toast.error(err.message);
      })
      .finally(() => isLoading.hide());
    },

    closeModal() {
      this.clearForm();
      this.$bvModal.hide('product-modal');
    },

    clearForm() {
      this.form = {
        batch_control: this.form.batch_control
      };
      this.validated = false;
    },

    isValidForm() {
      this.validated = true;
      let isValid = true;
      if (!this.form.name) {
        isValid = false;
      }
      if (!this.form.measure_unit_id) {
        isValid = false;
      }
      if (!this.form.expiration_notice || this.form.expiration_notice <= 0) {
        isValid = false;
      }
      if (!this.form.minimum_quantity || this.form.minimum_quantity <= 0) {
        isValid = false;
      }
      if (this.form.batch_control === null) {
        isValid = false;
      }
      return isValid;
    },

    isValidState(model) {
      return !this.validated || (model !== 'undefined' && model !== null && model !== '');
    },

    isValidStateNumber(model) {
      return !this.validated || (model !== 'undefined' && model !== null && model > 0);
    },
  },
  computed: {
    isValidName() {
      return this.form.name.length > 0;
    },

    invalidFeedbackName() {
      if (this.validated && (!this.form.name || this.form.name.length === 0)) {
        return 'Campo obrigatório';
      }
      return '';
    },

    isValidExpirationNotice() {
      return this.form.expiration_notice > 0;
    },

    invalidFeedbackExpirationNotice() {
      if (this.validated && (!this.form.expiration_notice || parseInt(this.form.expiration_notice) <= 0)) {
        return 'Campo obrigatório';
      }
      return '';
    },

    invalidFeedbackMinimumQuantity() {
      if (this.validated && (!this.form.minimum_quantity ||parseInt(this.form.minimum_quantity) <= 0)) {
        return 'Campo obrigatório';
      }
      return '';
    },

    isValidMinimumQuantity() {
      return this.form.minimum_quantity > 0;
    }
  },
  watch: {
    product: function(newValue) {
      if (newValue) {
        this.form = {
          ...newValue,
          batch_control: this.batchControlOptions.find(el => el.value === newValue.batch_control),
        };
      } else {
        this.clearForm();
      }
    }
  }
}
</script>
<style lang="scss">
#product-modal {
  .modal-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 750px;

    .modal-body {
      padding: 0;

      .modal-header {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px;

        svg {
          fill: var(--neutral-500);
        }
      }

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 24px;
      }

      .md-button {
        text-align: right;
        margin: 24px 24px 24px 0;

        .button {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          padding: 8px 16px;
        }
      }

      .row {
        margin: 0 !important;

        .title {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
          color: var(--dark-blue);
        }

        .optional {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 130%;
          align-items: center;
          color: var(--type-placeholder);
        }

        .no-padding {
          padding: 0 !important;

          hr {
            margin: 16px 0 0 0;
          }
        }

        .form-group {
          margin: 0 !important;
        }

        .padding_24_24_0 {
          padding: 24px 24px 0 !important;
        }

        .padding_24_0_0_24 {
          padding: 24px 0px 0 24px !important;
        }

        .padding_16_0_0_24 {
          padding: 16px 0px 0 24px !important;
        }

        .padding_24_24_0_8 {
          padding: 24px 24px 0 8px !important;
        }

        .padding_16_24_0_8 {
          padding: 16px 24px 0 8px !important;
        }

        .padding_16_0_0_8 {
          padding: 16px 0 0 8px !important;
        }
      }
    }
  }
}
</style>
